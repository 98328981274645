























import {
  Vue, Prop, Component, Watch,
} from 'vue-property-decorator';
@Component({
  name: 'search-bar',
})
export default class SearchBar extends Vue {
  @Prop() value!: string;

  @Prop({ default: '取消' }) extraBtn!: string;

  @Prop() maxlength!: number;

  @Prop({ default: false }) focus!: boolean;

  innerValue: string = '';

  mounted () {
    if (this.focus) {
      const el: any = this.$refs.input;
      el.focus();
    }
  }

  extraOprate () {
    this.$emit('extraOp');
  }

  debounce (fn: any, wait: number) {
    let timeout: any = null;
    return function () {
      if (timeout !== null) clearTimeout(timeout);
      timeout = setTimeout(fn, wait);
    };
  }

  clearContent () {
    this.innerValue = '';
  }

  handleInput (evt: any) {
    if (evt.target._composing) {
      return;
    }
    this.$emit('change', evt.target.value);
    this.$emit('input', evt.target.value);
  }

  @Watch('value')
  valueChange (v) {
    this.innerValue = v;
  }
}
